import React from "react";
import ProductProcessComponent from "../component/Services/productProcess";
import { graphql } from 'gatsby';
import FooterComponent from "../component/footerComponent";
import ServicesHomeComponent from "../component/Services/servicesHomeComponent";
import ProductImage from '../images/product_development_banner-min.jpg'
import Layout from "../component/layout";
import ServicesProductDevelopmentComponent from "../component/Services/servicesProductDevelopmentComponent";

const buttonItems = [
  "Conceptualize your MVP","Enhance your Product"
]
const ProductDevelopment = (props) =>{
    const{
        data:{
          wpPage:{
            seo,
            homePageHeaderSection :{
                homeHeaderDescription,
                homeHeaderTitle,
                // homeHeaderImage :{
                //   localFile :{
                //     childImageSharp :{
                //       gatsbyImageData:homeHeaderImage
                //     },
                //   },
                // },
              },
              processSection :{
                processAgileDescription,
                processAgileTitle,
                processAnalysisDescription,
                processAnalysisTitle,
                processDescription,
                processDesigningDescription,
                processDesigningTitle,
                processLaunchingDescription,
                processLaunchingTitle,
                processProductDescription,
                processProductTitle,
                processSubtitle,
                processSupportDescription,
                processSupportTitle,
              },
              serviceProductDevelopment :{
                serviceProductDevelopmentMvpText,
                serviceProductDevelopmentText,
                serviceProductDevelopmentTitle,
              },
          },
        },
        pageContext: { title },
    } = props;
    return(
        <Layout seo={seo} title={title}>
        <ServicesHomeComponent
          servicesHomeTitle={homeHeaderTitle}
          servicesDescription={homeHeaderDescription}
          servicesBackground={ProductImage}
        />
           <ServicesProductDevelopmentComponent
         buttonItems={buttonItems}
         productDeveloperTitle={serviceProductDevelopmentTitle}
         mvpDescription={serviceProductDevelopmentMvpText}
         productDescription={serviceProductDevelopmentText}
         />
        {/* <ProductProcessComponent
        processTitle="Our Process"
        productSubtitle={processSubtitle}
        productDescription={processDescription}
        analysisTitle={processAnalysisTitle}
        analysisDescription={processAnalysisDescription}
        conceptalizingTitle={processProductTitle}
        conceptalizingDescription={processProductDescription}
        designeTitle={processDesigningTitle}
        designeDescription={processDesigningDescription}
        agileTitle={processAgileTitle}
        agileDescription={processAgileDescription}
        launchingTitle={processLaunchingTitle}
        launchingDescription={processLaunchingDescription}
        supportTitle={processSupportTitle}
        supportDescription={processSupportDescription}
        /> */}
        <FooterComponent/>
        </Layout>
    )
}
export const query = graphql`
  query {
wpPage(slug: {eq: "product-development"}) {
  seo {
    metaDesc
    title
  }
  serviceProductDevelopment {
    serviceProductDevelopmentMvpText
    serviceProductDevelopmentText
    serviceProductDevelopmentTitle
  }
    homePageHeaderSection {
      homeHeaderDescription
      homeHeaderTitle
      homeHeaderImage {
        localFile {
          childImageSharp {
            gatsbyImageData(placeholder: BLURRED)
          }
        }
      }
    }
    processSection {
      processAgileDescription
      processAgileTitle
      processAnalysisDescription
      processAnalysisTitle
      processDescription
      processDesigningDescription
      processDesigningTitle
      processLaunchingDescription
      processLaunchingTitle
      processProductDescription
      processProductTitle
      processSubtitle
      processSupportDescription
      processSupportTitle
    }
  }
}
  `;
export default ProductDevelopment;